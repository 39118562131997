import firebase from 'firebase/compat';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import 'firebase/firestore'
import enviroment from "@/enviroment"

firebase.initializeApp(enviroment.firebase)

// init firestore

const projectFirestore = firebase.firestore()
const projectFireAuth = firebase.auth


export {
    projectFirestore, projectFireAuth,
}
