<template>
  <b-row>
    <b-col
        md="2"
        sm="4"
        class="my-1"
    >
      <b-form-group
          class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Por Página</label>
        <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
        md="4"
        sm="8"
        class="my-1"
    >
      <b-form-group
          label="Ordenar"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- ninguna --
              </option>
            </template>
          </b-form-select>
          <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
        md="6"
        class="my-1"
    >
      <b-form-group
          label="Filtrar"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Escriba para buscar"
          />
          <b-input-group-append>
            <b-button
                :disabled="!filter"
                @click="filter = ''"
            >
              Borrar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="leads"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(creado)="data">
          {{ data.value | date }}
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
    </b-col>
    <b-col
        cols="12"
    >
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

import {projectFireAuth, projectFirestore} from "@/firebase/config";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      leads: [],
      perPage: 20,
      pageOptions: [5, 10, 20, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'creado', label: 'Fecha', sortable: true
        },
        {
          key: 'fullName', label: 'Nombre', sortable: true
        },
        {
          key: 'age', label: 'Edad', sortable: true
        },
        {
          key: 'personalId', label: 'Documento Nº',
        },
        {
          key: 'workplace', label: 'Lugar de Trabajo',
        },
        {
          key: 'contactPhone', label: 'Teléfono',
        },
        {
          key: 'email', label: 'Correo',
        },
        {
          key: 'comments', label: 'Comentarios', enabled: false
        },
        { key: 'status', label: 'Status', sortable: true },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
    }
  },
  created(){
    projectFirestore.collection("leads").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());


        let fullName = doc.data().lead.fullName
        let personalId = doc.data().lead.personalId
        let age = doc.data().lead.age
        let workplace = doc.data().lead.workplace
        let contactPhone = doc.data().lead.contactPhone
        let email = doc.data().lead.email
        let comments = doc.data().lead.comments
        let creado = null
        if (doc.data().lead.createdAt){
          creado = doc.data().lead.createdAt.toDate()
        }

        this.leads.push({age:age, creado:creado, fullName:fullName, personalId:personalId, workplace:workplace, contactPhone:contactPhone, email:email, comments:comments, ...doc.data()})

        //this.leads.push(doc.data().lead)


      });
    });
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.leads.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
