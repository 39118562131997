import Vue from 'vue'
import VueRouter from 'vue-router'
import {projectFireAuth} from "@/firebase/config";
// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Listado de Leads',
                authRequired: true,
                breadcrumb: [
                    {
                        text: 'leads',
                        active: true,
                    },
                ],
                resource: 'Home'
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth'
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth'
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login') {
        const isLoggedIn = isUserLoggedIn()
        if (!isLoggedIn) {
            return next({name: 'login'})
        }
    }
    return next()
});

export default router
