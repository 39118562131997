import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import dayjs from 'dayjs'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'


Vue.filter('date', (value, format) => {
  let r = ''
  try {
    if (value) {
      if (typeof value.toDate === 'function') {
        r = dayjs(String(value.toDate())).format(format || 'MMM DD, YYYY - hh:mm A')
      } else {
        r = dayjs(String(value)).format(format || 'MMM DD, YYYY - hh:mm A')
      }
    }
    return r
  } catch (error) {
    console.error('date parser error: ', error)
    return r
  }
})


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
